import "../styles/footer.css";

export default function Footer() {
  return (
    <div className="footer">
      Website design and development by Evan Snow{" "}
      <i className="fal fa-copyright"></i> 2024
    </div>
  );
}
