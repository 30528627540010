import "../styles/contact.css";

export default function Contact() {
  return (
    <div className="contact-block">
      <h1 className="contact-title">Get in Touch...</h1>
      <div className="contact-link-block">
        <a
          href="https://www.linkedin.com/in/evanjsnow/"
          target="_blank"
          rel="noreferrer"
          className="primarybtn linkedin-btn"
        >
          <i className="fab fa-linkedin primarybtn-icon bump-up"></i>LinkedIn
        </a>
        <br />
        <a href="mailto: evansnow@gmail.com" className="primarybtn email-btn">
          <i className="fas fa-envelope primarybtn-icon bump-up"></i>Email Me
        </a>
      </div>
    </div>
  );
}
