import "../styles/main.css";
import bgImg from "../images/mainbg.jpg";

export default function Main() {
  return (
    <>
      <img src={bgImg} alt="snowy mountain background" className="mainbg" />
      <div className="logo-container">
        <p className="logo-name">
          Evan Sn<i className="fa-light fa-snowflake logo-icon"></i>w
        </p>
        <p className="logo-sub-text">Interactive Design &amp; Development</p>
      </div>
      <div className="down-arrow">
        <i className="fal fa-arrow-circle-down"></i>
      </div>
      <p className="scroll">Scroll Down</p>
    </>
  );
}
