import "../styles/welcome.css";

export default function Welcome() {
  return (
    <div className="welcome-container">
      <h1>Welcome.</h1>
      <p className="welcome-left-col">
        You've come to the right place for expertise in:
      </p>
      <p className="welcome-subheader">
        You've come to the right place
        <br /> for expertise in:
      </p>
      <table className="list-table">
        <tr>
          <td className="icon-column">
            <i className="fa-solid fa-bullseye-pointer welcome-icons"></i>
          </td>
          <td className="text-column">Interaction Design</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-solid fa-users-class welcome-icons"></i>
          </td>
          <td className="text-column">User Research</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-solid fa-user-gear welcome-icons"></i>
          </td>
          <td className="text-column">Usability Testing</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-solid fa-chart-column welcome-icons"></i>
          </td>
          <td className="text-column">Data Visualization</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-regular fa-universal-access welcome-icons"></i>
          </td>
          <td className="text-column">Accessibility</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-solid fa-code welcome-icons"></i>
          </td>
          <td className="text-column">HTML</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-solid fa-paintbrush welcome-icons"></i>
          </td>
          <td className="text-column">CSS / SCSS</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-brands fa-square-js welcome-icons"></i>
          </td>
          <td className="text-column">JavaScript</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-regular fa-atom-simple welcome-icons"></i>
          </td>
          <td className="text-column">React</td>
        </tr>
        <tr>
          <td className="icon-column">
            <i className="fa-regular fa-box-open-full welcome-icons"></i>
          </td>
          <td className="text-column">And Much More...</td>
        </tr>
      </table>

      <div className="icon-container">
        <i className="fa-solid fa-bullseye-pointer welcome-icons"></i>
        <i className="fa-solid fa-users-class welcome-icons"></i>
        <i className="fa-solid fa-user-gear welcome-icons"></i>
        <i className="fa-solid fa-chart-column welcome-icons"></i>
        <i className="fa-regular fa-universal-access welcome-icons"></i>
        <i className="fa-solid fa-code welcome-icons"></i>
        <i className="fa-solid fa-paintbrush welcome-icons"></i>
        <i className="fa-brands fa-square-js welcome-icons"></i>
        <i className="fa-regular fa-atom-simple welcome-icons"></i>
        <i className="fa-regular fa-box-open-full welcome-icons"></i>
      </div>
      <div className="term-list">
        <p className="term-row">Interaction Design</p>
        <p className="term-row">User Research</p>
        <p className="term-row">Usability Testing</p>
        <p className="term-row">Data Visualization</p>
        <p className="term-row">Accessibility</p>
        <p className="term-row">HTML</p>
        <p className="term-row">CSS / SCSS</p>
        <p className="term-row">JavaScript</p>
        <p className="term-row">React</p>
        <p className="term-row">And Much More...</p>
      </div>
    </div>
  );
}
