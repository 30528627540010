import "../styles/highlights.css";
import ImgCarousel from "./ImgCarousel";

export default function Highlights() {
  return (
    <div className="highlights-container">
      <div className="highlights-title">Highlight Reel</div>
      <ImgCarousel />
    </div>
  );
}
