import Main from "./components/Main";
import Welcome from "./components/Welcome";
import About from "./components/About";
import Highlights from "./components/Highlights";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

export default function App() {
  return (
    <>
      <Main />
      <Welcome />
      <About />
      <Highlights />
      <Contact />
      <Footer />
    </>
  );
}
