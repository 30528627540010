import "../styles/about.css";

export default function About() {
  return (
    <div className="about-container">
      <h1 className="about-title">About Me</h1>
      <div className="bio-block">
        <p>
          I built my first website with basic JavaScript in 1999, and later
          joined a UX group at EMC Dell styling early web apps. I was inspired
          by the way the team made design a data-driven process through human
          factors and user research. I asked to participate, and was trained by
          mentors with backgrounds at institutions like Bell Labs, Xerox/PARC,
          and Apple.
        </p>
        <p>
          Although my focus turned to usability, I've often provided styling
          code to help achieve a design vision. The past year I've focused on
          coding again, studying modern JavaScript, React, TypeScript, and more.
        </p>
        <p>
          My passion is designing and devloping products with an emphasis on
          user research and accessible frontend code, and I also love helping
          startups grow their products and refine their processes.
        </p>
      </div>
    </div>
  );
}
