import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/imgcarousel.css";
import { Carousel } from "react-responsive-carousel";
import ooa from "../images/samples/onlineordersall.png";
import oosingle from "../images/samples/oosingle.gif";
import billsall from "../images/samples/billsall.png";
import billsingle from "../images/samples/billsingle.gif";
import oportun from "../images/samples/oportun.png";
import dysiall from "../images/samples/dysiall.png";
import dysisingle from "../images/samples/dysisingle.png";
import paintings from "../images/samples/paintings.png";

export default function ImgCarousel() {
  return (
    <Carousel showThumbs={false} showStatus={false} showIndicators={false}>
      <div className="carousel-container">
        <div className="ooa">
          <img src={ooa} alt="Natera online ordering examples" />
        </div>
        <div className="oosingle">
          <img src={oosingle} alt="Natera online ordering examples" />
        </div>
        <div className="blurbbg">
          <p className="legend blurb">
            <span className="blurbtitle">
              Online Orders for Genetic Tests &mdash; Natera, Inc.
            </span>
            <span className="blurbtext">
              An easier way to order tests and educate patients boosted test
              volume and helped to increase revenue over 24% between 2019 and
              2020.
            </span>
          </p>
        </div>
      </div>
      <div className="carousel-container">
        <div>
          <div className="ooa billsall">
            <img
              src={billsall}
              alt="Natera's paper billing statement, before and after"
            />
          </div>
          <div className="ooa billsingle">
            <img
              src={billsingle}
              alt="Natera's updated paper billing statement"
            />
          </div>
          <div className="blurbbg">
            <p className="legend blurb">
              <span className="blurbtitle">
                Patient Billing Statement &mdash; Natera, Inc.
              </span>
              <span className="blurbtext">
                My work improving Natera’s billing process led to an increase in
                bills paid, a primary factor in a 24%+ increase in revenue over
                the previous year.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="carousel-container">
        <div>
          <div className="oportun">
            <img src={oportun} alt="Oportun online loan application" />
          </div>
          <div className="blurbbg">
            <p className="legend blurb">
              <span className="blurbtitle">
                Consumer-Facing Online Loan Application &mdash; Oportún
              </span>
              <span className="blurbtext">
                I led research and design for a digital loan application,
                contributing to an 80% increase in loan volume and a 2019 IPO.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="carousel-container">
        <div>
          <div className="dysiall">
            <img
              src={dysiall}
              alt="Dynamic Signal enterprise communications for iOS, before and after"
            />
          </div>
          <div className="dysisingle">
            <img
              src={dysisingle}
              alt="Dynamic Signal enterprise communications for iOS"
            />
          </div>
          <div className="blurbbg">
            <p className="legend blurb">
              <span className="blurbtitle">
                Enterprise Communications &mdash; Dynamic Signal
              </span>
              <span className="blurbtext">
                I led research and design for a product pivot from marketing to
                enterprise communications, leading to a competitor acquisition.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="carousel-container">
        <div>
          <div className="paintings">
            <img
              src={paintings}
              alt="Wildlife and landscape watercolor paintings by Evan Snow"
            />
          </div>
          <div className="blurbbg">
            <p className="legend blurb">
              <span className="blurbtitle">Watercolor Paintings</span>
              <span className="blurbtext">
                I really enjoy making things, with code or with my hands, and I
                particularly love to paint scenes from nature &#40;original work
                and reproductions&#41;.
              </span>
            </p>
          </div>
        </div>
      </div>
    </Carousel>
  );
}
